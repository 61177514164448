import "@style/reset.scss"
import "@style/variables.scss"
import "@style/responsive.scss"
import "@style/common.scss"
import "@style/extra.scss"
import "@style/tablet.scss"
import "@style/sp.scss"

export function onInitialClientRender () {
    let height=window.innerHeight;
    document.documentElement.style.setProperty( '--vh', height/100 + 'px');
}